import { remove } from 'firebase/database';
import React, { Component } from 'react';
//import join  from 'join-path'
import {join,firebase,pgText,textCutter} from '../../helper';

const {set, db, ref} = firebase.database;
class EditContentPage extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    getNavValue(ii, i){
        if(typeof this.props.data.navigation ==="object"){
            if(typeof this.props.data.navigation[ii] ==="object"){
                return this.props.data.navigation[ii][i] || "";
            }
        }
        return "";
    }
    navigation(ii, i ){
        const settingsByPage = pgText;
        const pages = this.props.data.pages;
        let options = [];
        Object.keys(pages).filter(key=>{
            const pg = settingsByPage[key] || pages[key];
            if(pg.type==="public"){
                if(typeof pg.slug === "string"){
                    options.push(pg.slug);
                } else {
                    options.push(key);
                }  
            }else{
                if(pages[key].style ==="page"){
                    options.push(key);
                }
               
            }
            return null;
        });
        
        const payload = { 
            path: join(this.props.path,'navigation',`${ii}/${i}`),
            active:true,
            type: "input",
            name: "navigation",
            page:"pages",
            choose:1,
            options:options,
            value:this.getNavValue(ii, i)
        };
      
        this.openPromt(payload);
    }
    createPage(){
        const name ="page_" + Math.floor((Math.random() * 100) + 1);
        const path = join(this.props.path,'pages',name);
        /* NEW FIREBASE */
        set(ref(db,path), {
            name:name,
            title:name,
            slug:name,
            image:"image.jpg",
            style:"page",
            content:"empty"
        });
       
    }
    deletePage(field,path){
        console.log(path);
        if(window.confirm(`Delete ${field} ?`)){
            remove(ref(db, path));
        }
    }
    saveConent(){
        //const val = this.refs.content.value;
        //const path = join(this.props.path,'pages',this.props.page);
        //set(ref(db, join(this.props.path,'pages',this.props.page)),val);
        
        //firebase.database().ref(path).child('content').set(val);
    }
    openPromt(payload){
        this.props.dispatch({type:"OPEN_PROMPT",payload:payload})
      
    }
    render(){
        const pages = this.props.data.pages;
        const fields = ["name","title","slug","content","image"];
        return <div className="section">
            <h1 className="title is-1">{pgText.pages.title}</h1>
            <div className="grid is-header">
                {fields.map((field)=>{ return <div key={field} className="grid-item">{field}</div>})}
                <div className="grid-item"></div>
            </div> 
            {Object.keys(pages).map((item, i)=>{
                const payload = { active:true,type: "input"};
                const ispage = (pages[item].style!=="page")? false: true;
                if(!ispage) return null;
                return <div className="grid" key={i}>
                    {fields.map((field)=>{
                        const value = pages[item][field];
                        const name = field;
                        const itemPL = {...payload,path: join(this.props.path,'pages',item, name),page:"pages",value: value, name: name};
                        return <div key={`${i}${field}`} onClick={this.openPromt.bind(this,itemPL)} className={`grid-item ${i===0? "":"grid-hover"}`}>{textCutter(value)}</div>
                    })}
                     <div className="grid-item">
                        {ispage?<a className="button is-small is-danger" onClick={this.deletePage.bind(this,item, join(this.props.path,'pages',item))}>DELETE</a>:null}
                     </div>
                </div>;        
            })}
           
            <a className="button is-medium" onClick={this.createPage.bind(this)}>Add Page</a>
            <div className="field-group">
                <h2 className="title is-5">Navigation</h2>
            </div>
                    
            {[0,1,2,3,4,5].map((i)=>{
                return <div key={i} className={`grid ${i===0? "is-first":""}`}> 
                    {[0,1,2,3,4,5,6,7].map((ii)=>{
                        let value = this.getNavValue(ii, i);
                        
                        return <div key={ii+"_" +i} onClick={this.navigation.bind(this,ii, i)} className="grid-item grid-hover">
                            {value !=="undefined" ? value :""}
                        </div>
                    })}
                </div>
            })}
       </div>
    }
}
export default EditContentPage;