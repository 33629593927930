import * as fbase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import { getDatabase, set, ref, onValue, remove, update, push} from "firebase/database";
import {app_fields, app_settings_fields} from './fields.js';
export const getParam = (w)=>{
    var pageUrl = window.location.href;
    var pg = pageUrl.split("/");
    var app = null;
    if(w==="app"){
        app = pg[3];
    }
    if(w==="page"){
        app = pg[4];
    }
    if(w==="subpage"){
        app = pg[5];
    }
    if(w==="item"){
        app = pg[6];
    }
    
    return app;
}

export const deleteMediaData = (user, key)=>{
    const db = getDatabase();
    const delRef = ref(db,`users/${user}/media/${key}` );
    remove(delRef);
    
    
}
export const saveMediaData = (user, data)=>{
    const db = getDatabase();
    const postListRef = ref(db,`users/${user}/media` );
    const newPostRef = push(postListRef);
    set(newPostRef, data);
}
const firebaseInit = ()=>{
    // Initialize Firebase
    // Initialize Firebase
    var config = {
        apiKey: "AIzaSyB2sb8pbPJqMlAWrRfW6ZArOxjl_pxxEpk",
        authDomain: "myweyoo.firebaseapp.com",
        databaseURL: "https://weyoopos.firebaseio.com",
        projectId: "weyoopos",
        storageBucket: "weyoopos.appspot.com",
        messagingSenderId: "673684801135"
    };
    fbase.initializeApp(config);
    

    const database = getDatabase();
    return {database:{db:database, ref, set,onValue,remove,update}, auth:{}};
}
export const join = (sA,sB,sC,sD,sF)=>{
    let str = "/";
        if(sA){ str +=sA;}
        if(sB){ str +="/" + sB;}
        if(sC){ str +="/" + sC;}
        if(sD){ str +="/" + sD;}
        if(sF){ str +="/" + sF;}
        
        return str;
}
/* save state to storage */
export const setStorage = (data)=>{
    localStorage.setItem("state", JSON.stringify(data));
}
export const getStorage = ()=>{
    let state = localStorage.getItem("state");
    return JSON.parse(state);
}
export const textCutter = (str)=>{
    if(isEmpty(str)) return false;
    let text = str.toString();
    const total = text.length;
    const max = 14;
    text = text.substring(0, max);
    return  (total > max)? text + "..." : text;
}
export const backup  = (data, fileName)=>{
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    var json = JSON.stringify(data,null,4),
        blob = new Blob([json], {type: "application/json"}),
        url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `${fileName}.json`;
    a.click();
    window.URL.revokeObjectURL(url);
}
export const sortObj = (obj) =>{
    if(typeof obj !=="object") return obj;
    let toObj = Object.keys(obj);
    toObj.sort(function(a, b) { 
        var x=obj[a].priority,
            y=obj[b].priority;
        return y - x;
    });
    return toObj;
}
export const onImageError = (e)=>{
    const src = "/assets/images/noimage.png";
    if(src !== e.target.src){
        e.target.src=src;
    }

}
export const isEmpty = (val)=>{

    return (val === undefined || val === null || val.length <= 0) ? true : false;
}
export const createGroups= (obj, prow) =>{
    const items = Object.keys(obj).reverse();
    const perRow   = prow || 4;
    const total = Math.ceil(items.length/perRow);
    var min = 0;
    var max = perRow;
    var arr = {};
    for (var i = 0; i < total; i++) {
        var group_items = {};
        const groupname = 'group' + i;
        for (var j = 0; j < items.length; j++) {
            if(j <= max-1 && j >= min){
                const key = items[j];
                group_items[key] = obj[key];
            }          
        }
        arr[groupname] = {items:group_items}
        max = max + perRow;
        min = min + perRow;
    }
    return arr;
}

export const firebase = firebaseInit();
export const sortedByPriority = (_items)=>{
    if(typeof _items !== "object"){
        return [];
    }
    var items = Object.keys(_items);
    var itemsArray = [];
    const dynamicSort = (property) => {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
    for (var i = 0; i < items.length; i++) {
        const key = items[i];
        var item = _items[key];
            item.key = key;
            itemsArray.push(item);
    }
    const sortedArray = itemsArray.sort(dynamicSort("-priority"));
    var finalArray = [];
    for (var j = 0; j < sortedArray.length; j++) {
        finalArray.push(sortedArray[j].key);
    }
    return finalArray;
};
export const queryParams = (search) =>{
    var s = search.substring(1);
    var query  = new URLSearchParams(s);
    let obj = {};
    for (let q of query) {
        obj[q[0]] = q[1];
    }
    return obj;
}
export const get = (obj, key, use) => {
    /* FUNCTION WILL BE DEPRECATED */
    return key.split(".").reduce(function(o, x) {
        return (typeof o === "undefined" || o === null) ? o : o[x];
    }, obj);
  
}
export const Image= (img)=>{
    // 11-4-2019 10:40pm
    if(typeof img ==="string"){
        var pattern = new RegExp('/', 'ig');
        var count = (img.match(pattern) || []).length;
        if(count !==0) {
            return img;
        } 
        let ext = img.search('.png') !== -1? '.png' : '.jpg'; 
            ext = img.search('.svg') !== -1? '.svg' : ext; 
            ext = img.search('.pdf') !== -1? '.pdf' : ext; 
        let filename = img.split('/').pop();
            filename = filename.split(ext)[0];
            ///assets/images/noimage.png
            if(ext===".pdf") return "https://img.icons8.com/?size=320&id=13593&format=png";
            if(!filename){
                return '/assets/images/noimage.png';
            }else{
                return `https://firebasestorage.googleapis.com/v0/b/weyoopos.appspot.com/o/media%2F${filename}${ext}?alt=media`;
    
            }
     }
    return null;
}
export const getThumbnail = Image;
export const updateField =(prompt) =>{
    const spchecker = /\S/.test(prompt.value);
    if(prompt.value && spchecker===true){
        if(prompt.name === "slug"){
            const ref = firebase.database().ref(prompt.path).parent;
            ref.once('value', function(snap){
                console.log(snap.val())
                console.log(snap.hasChild(prompt.value));
            });
        }else{
            firebase.database().ref(prompt.path).set(prompt.value)
        }
    }
}

export const pgText =  app_fields;

export const appSettingsFields =  app_settings_fields;