import React from 'react';
import EditMediaItems from './Media'
const MediaModal = (props) =>{
    const isActive = props.media_toggle? 'modal bring-front is-active': 'modal';
    return <div className={isActive}>
            <div className="modal-background"></div>
            <section className="modal-content"> 
                <EditMediaItems {...props} />     
             </section>
        </div>
}

export default MediaModal;