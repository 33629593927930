import React, { Component } from 'react';
import {firebase} from '../helper';
import { getAuth, signInWithEmailAndPassword} from "firebase/auth";
/*
March 2022
- this module was updated
*/
class Login extends Component {
    loginBtn(){
        const email = this.refs.email.value;
        const password  = this.refs.password.value;
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
        }).catch((error) => {
            alert(error.message)

        });
    }
    onKeyDown(e){
        if(e.key ==="Enter"){
            this.refs.submit.click();
        }
    }
    render(){
        return <section className="hero is-fullheight">
                
                    <div className="login-wrap">
                        <div className="field">
                           <label className="label">Email:</label>
                            <div className="control">
                                <input className="input" onKeyDown={this.onKeyDown.bind(this)} ref="email"/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Password:</label>
                            <div className="control">
                                <input className="input" onKeyDown={this.onKeyDown.bind(this)} ref="password" type="password"/>
                            </div>

                        </div>
                        <div className="field">
                            <div className="control">
                                <button className="button is-primary" ref="submit" onClick={this.loginBtn.bind(this)}>Login</button>
                            </div>
                        </div>
                    </div>
                  
                </section>
    }
}
export default Login;