import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import {Provider} from 'react-redux';
import {createStore} from 'redux';
import reducers from './reducers';
let initialState = {
    data: null, 
    fetched:false,
    media_toggle:false, 
    media_select:null,
    media_target:null,
    subpage:null,
    media: null, 
    user: null,
    form: {},
}
let store = createStore(reducers, initialState);
ReactDOM.render(<Provider store={store}>
    <App/>
</Provider>
, document.getElementById('root'));
registerServiceWorker();
