import React, { Component } from 'react';
class Loading extends Component {
    render(){
       return <div className="loader-wrap">
           <div className="loader-box">
               <i className="loader"></i>
           </div>
       </div>
    }
}
export default Loading