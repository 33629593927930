import React, { Component, useState } from 'react';
import {join,firebase} from '../../helper';
import { getDatabase,  ref, set, update,onValue } from "firebase/database";
//import join  from 'join-path'
const fields = [
    {name:"fullname",label:"Full Name"},
    {name:"pin",label:"PIN"},
    {name:"website",label:"Website ( just domain.com, no https )"},
    {name:"username",label:"Username",info:"Enter you website, domain.com enter domaincom"}];
const appData = {
        "navigation": [
            [
              "home"
            ]
        ],
        "pages": {
            "catalog":{
                "name":"Catalog",
                "slug":"Catalog",
                "style":"catalog",
                "groups":"",
                "cats":"",
                "items":"",
            },
            "contact": {
                "content": "empty",
                "items": {
                    "item1": "Full Name",
                    "item2": "Phone",
                    "item3": "Email",
                    "item6": "Message"
                },
                "locked": true,
                "name": "Contact us",
                "slug": "contact",
                "style": "content",
                "title": "content style page"
            },
     
            "gallery": {
                "content": "",
                "items": {
                    "item1": {
                        "image": "",
                        "subtitle": "",
                        "title": "R"
                    },
                    "item10": {
                        "image": "",
                        "subtitle": "",
                        "title": ""
                    },
                    "item2": {
                        "image": "",
                        "subtitle": "",
                        "title": ""
                    },
                    "item3": {
                        "image": "",
                        "subtitle": "",
                        "title": ""
                    },
                    "item4": {
                        "image": "",
                        "subtitle": "",
                        "title": ""
                    },
                    "item5": {
                        "image": "",
                        "subtitle": "",
                        "title": ""
                    },
                    "item6": {
                        "image": "",
                        "subtitle": "",
                        "title": ""
                    },
                    "item7": {
                        "image": "",
                        "subtitle": "",
                        "title": ""
                    },
                    "item8": {
                        "image": "",
                        "subtitle": "",
                        "title": ""
                    },
                    "item9": {
                        "image": "",
                        "subtitle": "",
                        "title": ""
                    }
                },
                "locked": true,
                "name": "Gallery",
                "slug": "gallery",
                "style": "gallery",
                "title": "Gallery"
            },
            "home": {
                "content": "empty",
                "name": "Home",
                "slug": "/",
                "style": "landing",
                "title": "Landing page"
            },
            "inbox": {
                "content": "empty",
                "locked": true,
                "name": "inbox",
                "slug": "inbox",
                "style": "content",
                "title": "inbox"
            }
        },
        "settings": {
            "address": {
                "address": "Business Address",
                "bizname": "Business Name",
                "city": "City",
                "email": "",
                "fax": "",
                "phone": "(000) 000-0000",
                "state": "NY",
                "zipcode": "00000"
            },
            "pages": {
                "address": {
                    "address": "Street Name",
                    "bizname": "Business Name",
                    "city": "City",
                    "email": "",
                    "fax": "",
                    "phone": "",
                    "state": "Ny",
                    "zipcode": "0000"
                },
                "catalog": {
                    "add_button": "Order ",
                    "h2": "10",
                    "name": "Menu",
                    "priority": "10",
                    "slug": "menu",
                    "subtitle": "Menu",
                    "title": "Menu"
                },
                "contact": {
                    "cellphone": "u7wnoyqovd9ghgjb2ky6ex7fkbns3q",
                    "item_0": "Full Name ",
                    "item_1": "",
                    "item_2": "",
                    "item_3": "hello",
                    "item_4": "",
                    "item_5": "",
                    "name": "Contact Us",
                    "phones": "",
                    "slug": "contact",
                    "title": "Contact Us"
                },
                "gallery": {
                    "0_image": "",
                    "0_title": "",
                    "1_image": "",
                    "1_title": "",
                    "2_image": "",
                    "2_title": "",
                    "3_image": "",
                    "3_title": "",
                    "4_image": "",
                    "4_title": "",
                    "5_image": "",
                    "5_title": "",
                    "6_image": "",
                    "6_title": "",
                    "7_image": "",
                    "7_title": "",
                    "8_image": "",
                    "8_title": "",
                    "max_pictures": "1",
                    "name": "Gallery",
                    "slug": "gallery",
                    "title": "My Site Gallery"
                },
                "home": {
                    "0_color": "",
                    "0_description": "",
                    "0_image": "",
                    "0_link": "",
                    "0_text": "",
                    "0_title": "",
                    "1_color": "a-is-white",
                    "1_description": "",
                    "1_image": "",
                    "1_link": "",
                    "1_text": "",
                    "1_title": "",
                    "2_color": "",
                    "2_description": "..",
                    "2_image": "",
                    "2_link": "",
                    "2_text": "",
                    "2_title": "",
                    "3_color": "",
                    "3_description": "",
                    "3_image": "",
                    "3_link": "",
                    "3_text": "",
                    "3_title": ""
                }
            },
            "theme": {
                "classLogo": "is-hidden",
                "cover": "",
                "logo": "",
                "navBg": ""
            }
        }
    };
class EditUserSettings extends Component {
    constructor(props){
        super(props);
        this.state = {AllowChangeUsername:false, form:this.props.settings}
    }
    onChange(name,e){
        let form = this.state.form;
            if(name ==="website"){
                // create username by input a website
                const domain = e.target.value.replace('.','');
                form.username = domain;
            }
            form[name] = e.target.value;
            console.log(form);
            this.setState(form);
    }
    saveSettings(){
        /* THIS NEED TO BE UPDATE */
        let form = this.state.form;
        const db = getDatabase();
        update(ref(db, 'users/' + this.props.user +"/settings/"),form );
        set(ref(db, 'users/' + this.props.user +"/apps/site"),appData);  
         
        alert("Saved");
   
    }
    
    render(){
        const values = this.props.settings || {};
        return <div className="section">
            <h1 className="title is-1">User Settings</h1>
            <div className="container">
            
            {fields.map((field, i)=>{
                if(field.name==="username" && values[field.name]){
                    return <div className="field" key={i}>
                         <label className="label">{field.label}: [ {this.state.form.username} ]</label>
                    </div>;
                }else{
                    if(field.name ==="username"){
                        // username
                        return <div className="field" key={i}>
                                <label className="label">{field.label}{this.state.form.username}</label>                 
                        </div>
                    }else{
                        return <div className="field" key={i}>
                            <label className="label">{field.label}</label>
                                <p className="control">
                                    <input onChange={this.onChange.bind(this, field.name)} ref={field.name} defaultValue={values[field.name]} className="input" />
                                </p>
                        </div>
                    }
                }  
            })}
            <div className="field">
                <div className="control">
                    <button onClick={this.saveSettings.bind(this)} className="button is-primary">Save changes</button>
                </div>
            </div>
            </div>
        </div>
    }
}
export default EditUserSettings;