import React, { Component } from 'react';
//import join  from 'join-path'
import {join,firebase,pgText} from '../../helper';
const {set, db, ref} = firebase.database;
class EditContactPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            form:{}
        }
    }
    onChange(eve){
        let form = this.state.form;
            form[eve.target.name] = eve.target.value;
            this.setState({form:form});
    }
    saveConent(){
        let form  = {};
        const path = join(this.props.path,'pages',this.props.page);
        Object.keys(this.state.form).map((field, i)=>{
            if(this.state.form[field]!==""){
                form[field] = this.state.form[field];
            }
            if(Object.keys(this.state.form).length === i +1){
                set(ref(db, path + "/items"), form)
                alert("Saved");
            }
            return null;
        });
    }
    componentWillMount(){
        if(this.props.data.pages["contact"].items != null){
            this.setState({form:this.props.data.pages["contact"].items});
        }
    }
    
    render(){
        let FormList = [];
        const types = {
            "item1":"input",
            "item2":"phone",
            "item3":"email",
            "item4":"input",
            "item5":"input",
            "item6":"textarea",
        }
        const formItems = this.props.data.pages["contact"].items || {};

        for(let i = 1; i < 7; i++) {
            var fieldName = "item"+ i;
            var itemValue = this.state.form[fieldName];
            var type = types[fieldName];
            FormList.push(<div key={i} className="formfield">
                <p><strong>Field {i}:</strong> [{type}]</p>
                <p>
                    <input className="input" defaultValue={itemValue} name={fieldName} onChange={this.onChange.bind(this)} type="text"/>
                </p>
            </div>);
        }
        return <div className="section">
            <h1 className="title is-1">{pgText.contact.title}</h1>
            <div className="columns">
                <div className="column">
                    {FormList}
                    <p><button className="button is-primary" onClick={this.saveConent.bind(this)}>Save changes</button></p>
                </div>
                <div className="column borderLeft">
                    <strong>Your Form Template:</strong>
                    {Object.keys(formItems).map((item)=>{
                        const name = formItems[item];
                        if(name.length !== ""){
                            return <p key={item}>{types[item]} : {name}</p>
                        }else{
                            return null;
                        }
                    })}
                    <ul>
                    <li><strong>- Leave field blank if you dont need it</strong></li>
                    <li><strong>- Check inbox for all messages you will get</strong></li>
                    </ul>
                </div>
            </div>   
        </div>
    }
}
export default EditContactPage;