import React, { Component } from 'react';
import {firebase,pgText} from '../../helper';
const {set, db, ref, onValue, remove} = firebase.database;
class EditContentPage extends Component {
    constructor(props){
        super(props)
        this.state = {messages: {}, order: {}};
    }
    loadInbox(){
        const username = this.props.settings.username;
        onValue(ref(db,`inbox/${username}/messages` ), (snapshot) => {
             const val = snapshot.val();
             this.setState({messages:val});
        }, {
         onlyOnce: true
        });
    }
    UNSAFE_componentWillMount(){
        this.loadInbox();
    }
    deleteItem(item){
        const username = this.props.settings.username;
        remove(ref(db,`inbox/${username}/messages/${item}`)).then((e)=>{
            alert(`inbox/${username}/messages/${item}`);
            this.loadInbox();
        }).catch((e)=>{
            alert("SOMETHING WRONG");
        });

    }
    render(){
        const messages = this.state.messages || {};
        return <div className="section">
            <h1 className="title is-1">{pgText.inbox.title}</h1>
            <div>{Object.keys(messages).map((message)=>{
                  
                    return <div className="notification is-warning" key={message}>
                         <button className="delete" onClick={this.deleteItem.bind(this,message)}></button>
                        <p><strong>From:</strong> {messages[message].item1}</p>
                        <p><strong>Phone:</strong> {messages[message].item2}</p>
                        <p><strong>Email:</strong> {messages[message].item3}</p>
                        <p><strong>Date:</strong> {messages[message].datetime}</p>
                        <hr/>
                        <p>{messages[message].item6}</p>
                    </div>
            })}</div>
          </div>
    }
}
export default EditContentPage;