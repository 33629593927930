import {firebase} from '../helper';
import { getAuth, onAuthStateChanged,signOut } from "firebase/auth";
import { getDatabase, ref, onValue} from "firebase/database";

const database = getDatabase();

//import join from 'join-path';
const userLogOut = () =>{
    const auth = getAuth();
    signOut(auth).then(() => {alert("see you soon :)")});
}
const fetchUserData = (dispatch)=>{
    // A BUG WAS FOUND HERE AND FIXED 2/19/2022
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if(user) {
            const db = getDatabase();
            const starCountRef = ref(db, 'users/' + user.uid );
            onValue(starCountRef, (snapshot) => {
              const data = snapshot.val();
                if(data !==null){
                    const appsdata = data['apps'] || {};
                    const mediadata = data['media'] || {};
                    const settingsdata = data['settings'] || {};     
                    dispatch({type:"FETCHED_SUCCESS",user: user.uid,settings:settingsdata, data: appsdata, media:mediadata})
                }else{
                    dispatch({type:"FETCHED_FAIL",user: user.uid,settings:{},data: {}, media: {}})
                }
              
            });
        }else{
            dispatch({type:"USER_FAIL"});
        }
    });
   
}

const Todos = (state = {}, action) =>{
    switch(action.type){
        case "COMPLETE_PAGES_SUCCESS":
            return state;
        case 'USER_LOGIN':
            return state;
        case 'ONCHANGE_PROMPT':
            let prompt = state.prompt;
                prompt.value = action.payload.value;
            return {...state, prompt:prompt}
        case 'CLOSE_PROMPT':
            return {...state, prompt: {active: false}}
        case 'OPEN_PROMPT':
            return {...state, prompt: action.payload};
        
        case 'PARENT_PROPS':
            return {...state, prompt: action.payload};
        case 'USER_LOGOUT':
            userLogOut();   
            return {...state, user: null}
        case 'UPDATE_FORM':
            return {...state,form: action.form}
        case 'MEDIA_TOGGLE':
            // TO ADD ANY REDUCER PROPS YOU HAVE TO DO IT FROM APp.js
            let form = state.form;
            let promptValues = state.prompt;
            if(action.media_selected=== true){
                form[state.media_target] = action.media_image; 
                if(promptValues.active){
                    // this will set prompt value
                    promptValues.value = form.image;
                }
            }
            return {...state,
                    media_toggle :!state.media_toggle,
                    media_select:action.media_select,
                    media_target:action.media_target,
                    form:form,
                    promptValues
                }
        case 'FETCH_DATA':
            fetchUserData(action.dispatch);
            return state;
        case 'USER_FAIL':
            return {...state,fetched:true, user:null}
        case 'FETCHED_FAIL':
            return {...state,
                    fetched:true,
                    settings:action.settings,
                    media:action.media,
                    form: action.form || {},
                    data: action.data,
                    prompt: {}, 
                    user: action.user
                };
        case 'FETCHED_SUCCESS':
            return {...state,
                        fetched:true, 
                        media:action.media,
                        form: action.form || {}, 
                        data: action.data, 
                        user: action.user,
                        prompt: {}, 
                        settings:action.settings
                    };
        default:
            return state
    }
}
export default Todos;